<template>
    <bounce-loader
        :loading="spinner.loading"
        :color="spinner.color"
        :size="spinner.size"
        align="center"
        class="pt-16"
    >
    </bounce-loader>
</template>

<script>
import BounceLoader from "vue-spinner/src/BounceLoader";

/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "Message",
  data(){
    return {
      spinner: {
        loading: true,
        color: "#6D7E6D",
        size: '70px'
      }
    }
  },
  components:{
    BounceLoader,
  },
}
</script>
